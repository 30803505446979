.ui.large.modal
{
    width: 50% !important;
}

.modal40.ui.large.modal
{
    width: 40% !important;
}
.dropdownw25 
{
    width: 25rem !important;
}
.dropdownw12
{
    width: 12rem !important;
}
.dropdownw13
{
    width: 13rem !important;
}
.textarea25 textarea
{
    min-width:395px !important;
    min-height:3.875rem !important;
    
}
.w16 .input-box
{
    width: 16rem !important;
}

.w11
{
    width: 11rem !important;
}
.w14 .input-box ,.w14
{
    width: 14rem !important;
}

.w20 .input-box
{
    width: 20rem !important;
}
.w12 .input-box
{
    width: 12rem !important;
}

.w16 .input-box
{
    width: 16rem !important;
}

.w16 .input-box
{
    width: 16rem !important;
}

.mh2 textarea
{
    min-height:2.875rem !important;
}
.result
{
    text-align: left;
}
.cancelCall
{
    border:.0625rem solid black !important;
    background-color:black !important;
    margin-left: 20px !important; 
}
.editAssignAssignTo.ui.button.link
{
    color:#000 !important;
    font-weight:600 !important;
}

.ml70
{
    margin-left:70px;
}
.searchGDA 
{
    width: 445px;
    margin-left: -41px;
}
.labelAssignSearch .input-label-message
{
    margin-left:109px !important;
}
.column50
{
    width:50% !important;
}
.columnRight50
{
    width:50% !important;
    margin-left:50% ;
    margin-top:-22% ;
}
.textCenter
{
    text-align: center !important;
}
.textRight
{
    text-align: right !important;
}
.mtminus40
{
    margin-top: -40px !important;
}
.addBtnHead
{
    text-align: right;
    margin-top: -54px;
    margin-bottom: 20px;
}
.statusDivTag
{
    margin-left:25%;
}
.ml100
{
    margin-left:100px;
}
.ml90
{
    margin-left:90px;
}
.ml30
{
    margin-left:147px;
}
.ml80
{
    margin-left:80px;
}
.ml45
{
    margin-left:45px;
}
.ml115
{
    margin-left:115px;
}
.statusDiv
{
    margin-left:25%;
}
.moving
{
    margin-left: 70%;
    margin-top: -55px;
}
.divConfirmCancel
{
    width: 100%;
    text-align: right;
    margin:10px;
}
html:not(.honeywell-dark-theme) .ui.single-input .input-box
{
    width: 25rem;
}

.taskType .scuf-dropdown-wrapper
{
    width: 25rem;
}
.m10
{
    margin: 10px;
}
.mr10
{
    margin-right: 10px;
}
html:not(.honeywell-dark-theme) .ui.scuf-dropdown-wrapper
{
    width:12rem;
}
.taskType .dropdown
{
    width:25rem;
}
.wselect23 
{
    width:23rem !important;
}
.wselect23 input
{
    width:23rem !important;
}
html:not(.honeywell-dark-theme) .ui.button
{
    font-size:0.750rem !important;
}
