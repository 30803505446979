.sidebar-layout-height {
    height: 100%;
}

.mt100 
{
    margin-top: 50px !important;
}
/**** MARGIN LEFT ****/
.ml10 {
    margin-left: 10px !important;
}
.ml20 {
    margin-left: 20px !important;
}
.ml30 {
    margin-left: 30px !important;
}
.ml50 {
    margin-left: 50px !important;
}

.ml70 {
    margin-left: 70px !important;
}
.ml85 {
    margin-left: 85px !important;
}

.ml105
{
    margin-left: 105px !important;
}
.ml115
{
    margin-left: 115px !important;
}

.ml120 {
    margin-left: 120px !important;
}
.ml127 {
    margin-left: 127px !important;
}

.ml190 {
    margin-left: 190px !important;
}
.ml197 {
    margin-left: 197px !important;
}
.ml230 
{
    margin-left: 230px !important;
}
.ml245 
{
    margin-left: 245px !important;
}
.ml250 
{
    margin-left: 250px !important;
}
.ml300 
{
    margin-left: 300px !important;
}
.ml400 {
    margin-left: 400px !important;
}
.ml460 {
    margin-left: 460px !important;
}
.ml490 {
    margin-left: 490px !important;
}
.ml690 {
    margin-left: 690px !important;
}
.ml920 {
    margin-left: 920px !important;
}
.ml1090 {
    margin-left: 1090px !important;
}

/**** MARGIN LEFT ****/
.backWhite
{
    background-color: white !important;
}

.systemPreference
{
    width: 23%;
    margin-top: 10px;
} 
.pageLayoutBleStyle
{
    width: 78%;
    margin-top: -142px;
    margin-left: 250px !important;
    background-color: white;
    padding-top: 1px;
    padding-bottom: 20px;
}
.imgInput
{
    display:none;
}
.PageLayoutWorkflow
{
    width: 78%;
    margin-top: -256px;
    margin-left: 250px !important;
    background-color: white;
    padding-top: 1px;
    padding-bottom: 20px;
}
.custom-file-upload {
    border-radius: 40%;
    display: inline-block;
    position: relative;
    padding: 6px;
    cursor: pointer;  
    margin-bottom: 25px;
  }
  .custom-file-upload img
  {
    width:150px !important;
    border-radius:50%
  }
  .profileInput .input-box
  {
      width: 21rem !important;
  }
  .profileInput .dropdown 
  {
      width: 21rem !important;
  }
  .profileSelect
  {
    width: 21rem !important;
  }
  .headerCard
  {
    margin-left: -16px;
    margin-top: -16px !important;
    margin-right: -16px;
    color: #fff;
    text-align: center;
    padding: 10px;
    font-weight: bold;
  }
  .center
  {
      text-align: center;
  }
  .headerCardWhite
  {
    margin-left: -16px;
    margin-top: -16px !important;
    margin-right: -16px;
    color: #000;
    text-align: center;
    padding: 10px;
    font-weight: bold;
  }
  .bgCircle
  {
      background-color: #000;
      border-radius: 50%;
      margin-right: 5px;
      color: #fff;
      padding: 3px;
      padding-left: 6px;
      text-align: center;
  }
  .blue
  {
      background-color: #1792E5;
  }
  .green
  {
      background-color: #7EB338;
  }
  .yellow
  {
      background-color: #F5A623;
  }
  .gray
  {
      background-color:gray;
  }
  .red
  {
    background-color: #B9261C;
  }
  .bold
  {
      font-weight: bold !important; 
  }
 .mb10
  {
    margin-bottom:10px !important;
  }
  .btnMedium .button-content
  {
      font-size: 1rem !important;
  }
 .reportExportIcon {
    padding-right: 0.55rem !important;
    padding-top: 7px !important;
}
.ui.scuf-grid
{
    margin-left:-7px !important;
}
.main-logo
{
    width:11.3rem !important;
    height:3rem !important;
}
.datetime-date
{
    margin-left: 0px !important;
    margin-right: 20px;
}
.picker-input.datetime-time  .input-box
{
    border-left:1px solid #D0D0D0 !important;
}
.below-text
{
    min-height:0.25rem !important;
}
.mh2 textarea
{
    min-width:403px !important;
}
.flow1
{
    border-top: 3px solid grey;
    border-right: 3px solid grey;
    margin-left: 260px;
    margin-top: -240px;
    width: 73px;
    height: 39px;
}
.flow2
{
    border-top: 3px solid grey;
    border-right: 3px solid grey;
    margin-left:480px;
    margin-top:-230px;
    width: 73px;
    height: 39px;
}
.flow3
{
    border-top: 3px solid grey;
    border-right: 3px solid grey;
    margin-left:720px;
    margin-top:-230px;
    width: 73px;
    height: 39px;
}

.flow4
{
    border-top: 3px solid grey;
    border-right: 3px solid grey;
    margin-left:940px;
    margin-top:-230px;
    width: 73px;
    height: 39px;
}
.flow5
{
    border-top: 3px solid grey;
    border-right: 3px solid grey;
    margin-left:940px;
    margin-top:-230px;
    width: 73px;
    height: 39px;
}
.cardFlow
{
    width: 220px !important;
}
.flowBtn
{
    padding-left:1rem !important;
    padding-right: 1rem !important;
}