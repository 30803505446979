.max-width {
    width: auto;
}

html, body, #root, .App {
    height: 100%;
  }

  .table-icon {
    height: 20px;
  }

.loader-frame {
    position: relative;
}
.contact-buttons {
    width: 120px;
    height: 100px;
    display: inline-block;
    background: #F7F7F7;
    border: 1px solid rgb(189, 189, 189);
    text-align: center;
    color: #4291C6;
    padding: 1.5em;
    margin: 0.5em;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: 600;
}

.separator {
    padding: 1.5em;
    height: 100px;
    border-left: 1px solid #979797;
    margin-left: -20px;
    margin-top: 1em;
}

.contact-us-area .submit-button {
    text-align: right !important;
}

.contact-us-resource-title {
    padding-left: 20px;
    border-left: 1px solid #979797;
}

.contact-us-resources.row {
    display: block !important;
    text-align: center;
    padding: 10px;
    padding-top: 30px;
}

.contact-us-resources.h-icon {
    color: #4291C6;
}

.loader-frame {
    position: relative;
}
.action-bar-item {
    color:#000;
  
}

.addBtnHead
{
    text-align: right;
    margin-top: -54px;
    margin-bottom: 20px;
}
.ui.modal
{
    text-align: center;
}

.ui.large.modal
{
    width: 60% !important;
}
.statusDivTag
{
    margin-left:25%;
}
.statusDiv
{
    margin-left:25%;
}
.moving
{
    margin-left: 70%;
    margin-top: -55px;
}
.divConfirmCancel
{
    width: 100%;
    text-align: right;
    margin:10px;
}
html:not(.honeywell-dark-theme) .ui.single-input .input-box
{
    width: 25rem;
}

.taskType .scuf-dropdown-wrapper
{
    width: 25rem;
}
.m10
{
    margin: 10px;
}
.mr10
{
    margin-right: 10px;
}

.taskType .dropdown
{
    width:25rem;
}
html:not(.honeywell-dark-theme) .ui.button
{
    font-size:0.750rem !important;
}